import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage15() {
  const question = {
    title: 'Frage 15 von 35',
    headline: '',
    subline: 'Die Arbeit mit Medizintechnik finde ich super spannend',
  }

  const answers = [
    {
      name: 'Medizintechnik',
      id: 'ja',
      value: 'Ja',
      desc: '',
    },
    {
      name: 'Medizintechnik',
      id: 'gelegentlich',
      value: 'Gelegentlich',
      desc: '',
    },
    {
      name: 'Medizintechnik',
      id: 'nein',
      value: 'Nein, gar nicht.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={15}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-16`}
          prev={`${AREA_CARE_PATH}/spielen/frage-14`}
          index={15}
        />

        <Icon position="3" name="grid" />
      </main>
    </>
  )
}
